<template lang="pug">
div
  Header(:documentStatus="{ status: documentData?.status, time: documentData?.time_check, verification_number: documentData?.verification_number }")
  div.mb-25.mt-20
    div.document-content-title {{ $t(`documentInfo.${documentData.service_type_document}`) }}
    div.document-content-title.mb-5 № {{ documentData?.number_document }}
    div.document-content-position {{ documentData?.rank?.[langFields] }}
  div.d-flex.flex-column
    div.mb-20
      div(v-if="documentData?.number_document").document-content-column-title {{ $t('documentInfo.documentNumber') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.number_document }}
      div(v-if="documentData?.name_ukr").document-content-column-title {{ $t('documentInfo.nameInUkr') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.name_ukr }}
      div(v-if="documentData?.name_eng").document-content-column-title {{ $t('documentInfo.nameInEng') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.name_eng }}
      div(v-if="documentData?.date_birth").document-content-column-title {{ $t('documentInfo.dateOfBirth') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_birth }}
      div(v-if="documentData?.sex?.[langFields]").document-content-column-title {{ $t('documentInfo.sex') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.sex?.[langFields] }}
      div(v-if="documentData?.citizenship?.[langFields]").document-content-column-title {{ $t('documentInfo.citizenship') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.citizenship?.[langFields] }}
      div(v-if="documentData?.position?.[langFields].length").document-content-column-title {{ $t('documentInfo.position') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.position?.[langFields]?.[0].position }}
      div(v-if="documentData?.date_issued").document-content-column-title {{ $t('documentInfo.dateOfIssueDocument') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_issued }}
      div(v-if="documentData?.date_terminate").document-content-column-title {{ $t('documentInfo.expiryDate') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_terminate }}
      div(v-if="documentData?.rule").document-content-column-title {{ $t('documentInfo.competenceStandardSTCWConvention') }}
        div.fw-500.black--text.align-self-end.text-right {{ documentData?.rule }}
    div(v-if="documentData?.func_level_limit?.[langFields]")
      div.document-content-table-title.mb-6 {{ $t('documentInfo.additionalData') }}
      table.w-100.text-left
        thead
          tr
            th.document-content-table-title-column {{ $t('documentInfo.function') }}
            th.document-content-table-title-column {{ $t('documentInfo.level') }}
            th.document-content-table-title-column {{ $t('documentInfo.limitation') }}
        tr(v-for="(item, index) in documentData?.func_level_limit?.[langFields]" :key="index").document-content-table-content
          td.pl-3 {{ item?.func }}
          td.pl-3 {{ item?.level }}
          td.pl-3 {{ item?.limitation }}
    div(v-if="documentData?.position?.[langFields]").mt-15
      div.document-content-table-title.mb-6 {{ $t('documentInfo.positionOrRestrictions') }}
      table.w-100.text-left
        thead
          tr
            th.document-content-table-title-column {{ $t('documentInfo.currentPosition') }}
            th.document-content-table-title-column {{ $t('documentInfo.limitation') }}
        tr(v-for="(item, index) in documentData?.position?.[langFields]" :key="index").document-content-table-content
          td.pl-3 {{ item?.position }}
          td.pl-3 {{ item?.limitation }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Qualification',
  props: { documentData: { type: Object, default: () => ({}) } },
  components: { Header: () => import('@/components/layouts/DocumentHeader') },
  computed: { ...mapState({ langFields: state => (state.app.lang === 'en') ? 'eng' : 'ukr' }) }
}
</script>